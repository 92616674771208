import { Col, Container, Row } from 'react-bootstrap';
import React, {
  ChangeEvent,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { TGameFillInGame } from './types';
import { useGameMechanic } from 'src/hooks';
import GameNotFound from '../../../not-found';
import ImgWithZoom from '../../../../components/img-with-zoom';
import parse, { Element } from 'html-react-parser';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The "fill in" game component
 * @constructor
 */
function FillInGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
    points
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  /**
   * @description
   *    The variables for internal state and reference
   */

  const inputsRef = useRef<HTMLInputElement[] | null[]>([]);
  const [activeCell, setActiveCell] = useState<number[]>([0, 0]);
  const [filled, setFilled] = useState<Record<string, any>>({});

  /**
   * @description
   *    The content of the fill in the game component
   */
  const { text, answers, filePath } = gameMechanic.content as unknown as TGameFillInGame;
  const textArr = useMemo(() => text.split('{{_}}'), [text]);

  const lastLetter = useMemo(() => {
    let [order, letterIndex] = activeCell;
    return letterIndex % 100 === answers[order].length - 1;
  }, [activeCell]);

  /**
   * @description
   *    The keyboard event handler method to track the arrows and back-press
   *    keyboards
   * @param { React.KeyboardEvent<HTMLInputElement> } event
   *    The keyboard event variable
   */
  function keyCheck(event: React.KeyboardEvent<HTMLInputElement>) {
    let keyId = event.key;
    let [order, letterIndex] = activeCell;
    switch (keyId) {
      case 'Backspace':
        setFilled(prev => ({ ...prev, [order]: { ...prev[order], [letterIndex]: '' } }));
        if (letterIndex === 0) return;
        setActiveCell([order, letterIndex - 1]);
        let prevCell = order * 100 + letterIndex - 1;
        inputsRef?.current?.[prevCell]?.focus();
        break;
      case 'ArrowLeft':
        if (letterIndex === 0) return;
        setActiveCell([order, letterIndex - 1]);
        break;
      case 'ArrowRight':
        if (lastLetter) return;
        setActiveCell([order, letterIndex + 1]);
        break;
      default:
        break;
    }
  }

  /**
   * @description
   *    The fill in the game input event handler method
   * @param e
   * @param order
   * @param letterIndex
   */
  function handleInput(e: ChangeEvent<HTMLInputElement>, order: number, letterIndex: number) {
    let letter = e.target.value;
    if (!letter) return;
    if (filled[order]) {
      setFilled(prev => ({ ...prev, [order]: { ...prev[order], [letterIndex]: letter } }));
    } else {
      setFilled(prev => ({ ...prev, [order]: { [letterIndex]: letter } }));
    }
    if (letter) {
      if (!lastLetter) {
        let nextCell = order * 100 + letterIndex + 1;
        setActiveCell([order, nextCell]);
        inputsRef?.current?.[nextCell]?.focus();
      } else if (order < answers.length - 1) {
        let nextCell = (order + 1) * 100;
        setActiveCell([order + 1, nextCell]);
        inputsRef?.current?.[nextCell]?.focus();
      }
    }
  }

  function calcCorrectAnswer() {
    if (filled) {
      let calculatedPoints = 100;
      Object.values(filled).map((wordData, index) => {
        if (answers?.length < index || answers[index] !== Object.values(wordData).join('')) {
          calculatedPoints = 0;
        }
      });

      if (calculatedPoints > 0) {
        // changeCorrectAnswersPoints(calculatedPoints + points);
      }
    }
  }

  useEffect(() => {
    calcCorrectAnswer();
  }, [filled]);

  const customParse = (node: Element, index: number) => {
    if (node.attribs && node.attribs.id && node.attribs.id === `replace-${index}`) {
      const replacementContent = <span className='inputs'>
               {answers[index].split('').map((l: string, k) => (
                 <input
                   ref={(el) => inputsRef.current[index * 100 + k] = el}
                   key={`text-${k}-${l}`}
                   className='blank-input'
                   value={filled[index]?.[k] || ''}
                   onKeyDown={keyCheck}
                   maxLength={2}
                   onClick={() => setActiveCell([index, index * 100 + k])}
                   onChange={(e) => handleInput(e, index, k)}
                 />
               ))}
                              </span>;
      return (<div key={node.attribs.id}>{replacementContent}</div>);
    }
    return node;
  };

  return (
    <div className='fill-in-game'>
      <Container>
        <Row>
          {filePath &&
          <Col lg={5} md={12}>
            {filePath.includes('video') && (
              <ImgWithZoom filePath={filePath} type='video' />
            )}

            {filePath.includes('image') && (
              <ImgWithZoom filePath={filePath} />
            )}
          </Col>
          }
          <Col lg={7} md={12}>
            <div className='description'>
              {textArr.map((text, i) => {
                  if (i < answers.length) {
                    return parse(`<span key='${i}'>${text} <div id='replace-${i}'><div></span>`, { replace: (node) => customParse(node as Element, i) });
                  }
                }
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default memo(FillInGame);
