import { motion } from 'framer-motion';
import { memo, useMemo } from 'react';
import HintPopup from './hint-popup';
import InstructionsPopup from './instructions-popup';
import { useEditGame } from '../../hooks';

function EditorPopups() {
  const {
    isShowHints,
    isShowInstructions,
    showTheInstructionsPopup,
    game,
    updateGame,
  } = useEditGame();
  return (
    <>
      {isShowHints ? (
        <motion.div
          className='popup-wrapper'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <HintPopup
            key={`editor_hints_${game?.id}`}
            game={game}
            updateGame={updateGame}
          />
      </motion.div>) :
        isShowInstructions ? <motion.div className='popup-wrapper'
                                         initial={{ opacity: 0 }}
                                         animate={{ opacity: 1 }}
                                         exit={{ opacity: 0 }}><InstructionsPopup description={game?.description}
                                                                                  close={() => showTheInstructionsPopup(false)} />
        </motion.div> : null}
    </>
  );
}

export default memo(EditorPopups);