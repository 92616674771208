import { Col, Row } from 'react-bootstrap';
import { ButtonMotion } from '../buttons';
import { ChangeEvent, memo, useState } from 'react';
import 'src/assets/styles/hint-popup.scss';
import { useEditGame } from '../../hooks';
import Coin from '../../assets/svg/coin';
import { IHint } from './hint-interface';
import {TGame} from "../../@types";
import EditorCloseBtn from '../../assets/svg/buttons/editor-close-btn';

const coins = [0, 1, 2, 3];

function HintPopup({game}: { game: TGame | null, updateGame: (game: TGame) => void }) {
  const [hints, setHints] = useState<IHint[]>(game?.hints ?? []);
  const [selectedScore, setSelectedScore] = useState(25);
  const [currentHint, setCurrentHint] = useState('');
  const [toDelete, setToDelete] = useState<number[]>([])

  const {showTheHintsPopup, updateGame} = useEditGame()

  const addHint = () => {
    const currentHints = [...hints, { hint: currentHint, score: selectedScore }];
    setHints(currentHints);
    setCurrentHint('');
    setSelectedScore(25);

    if (game) {
      updateGame({
        ...game,
        hints: [...hints, { hint: currentHint, score: selectedScore }]
      })
    }
  };

  function updateToDelete(e:ChangeEvent<HTMLInputElement>, index:number){
    if(e.target.checked){
      setToDelete(prev=> ([...prev, index]))
    }else{
      setToDelete(prev=> prev.filter(i=> i !== index))
    }
  }

  function deleteHints(){
    toDelete.map(h=> setHints(prev=> prev.slice(0,h).concat(prev.slice(h+1))))
    setToDelete([]);
    if (game) {
      updateGame({
        ...game,
        hints: []
      })
    }
  }

  return (
    <>
      <div className='popup-header'>
        <h2>Add hint</h2>
        <EditorCloseBtn close={() => showTheHintsPopup(false)} />
      </div>
      <div className='hint-body'>
        <Row>
          <Col md={10}><textarea onChange={(e)=>setCurrentHint(e.target.value)} value={currentHint} className='form-control'
                                placeholder='write here each hint content '></textarea></Col>
          <Col md={2}>
            <div className='scores'>
              <h4>select the hint value</h4>
              <ul className='coins'>
                {coins.map(coin => <li onClick={() => setSelectedScore(coin)}
                                       className={selectedScore === coin ? 'selected' : ''}
                                       key={coin}>{coin} coins</li>)}
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonMotion disabled={!currentHint} variant='custom' className='add-hint-btn'
                          onClick={addHint}>Add Hint</ButtonMotion>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='hints clearfix'>
              {hints.length > 0 ?
                <>
                <ul>
                  {hints.map((hint, i) => {
                    return <li key={`hint-${i}`}>  <p  className='custom-checkbox'>
                      <input
                        type='checkbox'
                        checked={toDelete.includes(i)}
                        id={`type-${i}`}
                        name='type'
                        onChange={(e) => updateToDelete(e, i )}
                      />
                      <label htmlFor={`type-${i}`}>{hint.hint}/{hint.score} </label> <span className='coin-svg'><Coin /></span>
                    </p></li>;
                  })}
                </ul>
                  <ButtonMotion className="float-end" variant={"danger"} onClick={deleteHints}>Delete Hints</ButtonMotion>
                </>
                :
                <h2>No hints added</h2>
              }

            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default memo(HintPopup);