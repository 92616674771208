import { createContext, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { TGame, TGameMechanic } from 'src/@types';
import { EnumGameStatus } from 'src/@constants';
import {
  getGameMechanic,
  changeGameEditMode,
  createGameNewMechanic,
  updateGame as updateGameData,
  submitGame as submitGameToServer,
  closeGame as resetAndCloseGameData,
  showTheHintsPopup,
  showTheInstructionsPopup
} from 'src/redux/slices';
import { Loading } from 'src/components';
import {useAuth, useGame} from 'src/hooks';
import {useNavigate} from "react-router-dom";
import {  PATH_EDIT, PATH_GAMES } from '../routes/paths';
import AzureAuth from "../azure-component/AzureAuth";
// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    Global type/context for all functional games.
 *    The interface contains all necessary methods/variables/lifecycles for the
 *    games that are recognized by the main controller of the game.
 * @param { Error | string | NaN } error
 *    The error variable that may contain an error object if there is any error
 *    during the fetching process of the game data and UI
 * @param { boolean } isEditMode
 *    The boolean variable that shows if the game is in edit mode or not
 * @param { function } changeEditMode
 *    The method is changing the edit mode based on the provided input mode
 * @param { boolean } isPaused
 *    The variable contains the boolean value that shows if the game is in pause
 *    mode or not
 * @param { function } pauseGame
 *    The method is switching the game status from pause to playing
 * @param { boolean } isLoading
 *    The boolean variable that represents the game fetching process
 */
export interface GameEditInterface {
  // Data
  error: string | Error | null;
  isLoading: boolean;
  game: TGame | null;
  gameMechanic: TGameMechanic | null;
  page: number;
  totalPages: number;
  isShowHints:boolean,
  isShowInstructions:boolean,
  // Common methods
  changePage: (pageNumber: number) => void;
  createGameMechanic: (gameMechanicData: TGameMechanic, gameId:string) => void;
  updateGame: (finalGameData: TGame) => void;
  submitUpdatedGame: (finalGameData: TGame) => void;
  closeGame: VoidFunction;
  showTheHintsPopup:(payload:boolean)=> void
  showTheInstructionsPopup:(payload:boolean)=> void
}

/**
 * @description - Games base context to create context of games' lifecycle
 */
export const ContextGameEdit = createContext<GameEditInterface | null>(null);

/**
 * @description - Games' base lifecycle Provider hook. Contains lifecycle logic
 * @param children - Children components
 * @constructor - Adding listener for game lifecycle
 */
export function GameEditProvider({ children }: { children: ReactNode }) {

  // ========================== INTERNAL VARIABLES ========================== //
  /**
   * @description - The global state-based variables and methods
   */
  const dispatch = useDispatch();
  const navigate=useNavigate();
  // Getting the internal game related state
  const {
    error,
    isLoading,
    status,
    game,
    gameMechanic,
    page,
    isShowHints,
    isShowInstructions
  } = useSelector((state) => state.game);
  const { user } = useAuth();

  /**
   * @description
   *    The variables and common methods from game's based hook
   */
  const { changePage, gameId, isEditMode } = useGame();
  // ======================================================================== //
  // ========================== LIFECYCLE METHODS =========================== //
  // ======================================================================== //

  /**
   * @description
   *    The method is creating the empty content of the game mechanic
   * @param gameMechanicData
   */
  const createGameMechanic = (gameMechanicData: TGameMechanic, gameId: string) => {
    dispatch(
      createGameNewMechanic(gameMechanicData)
    )
    navigate(`/${PATH_GAMES}/${PATH_EDIT}/${gameId}`)
  }

  /**
   * @description
   *    The method is saving the game mechanic into the game object for the
   *    internal state
   */
  const updateGame = (finalGame: TGame) => {
    if (game) {
      dispatch(updateGameData({ ...finalGame }));
    }
  }

  /**
   * @description
   *    The method is saving the edited game
   */
  const submitUpdatedGame = (newGame?: TGame) => {
    let finalGame = newGame ?? game;
    if (finalGame) {
      dispatch(submitGameToServer({
        ...finalGame,
        updated_by: user?.account.username ?? user?.account.localAccountId,
      }, () => {
        dispatch(changeGameEditMode(false));
        dispatch(resetAndCloseGameData());
        // navigate((`/${PATH_GAMES}/${gameId}`));
        window.location.href = `${process.env.REACT_APP_STAGE_APP_ROOT_PATH}/${PATH_GAMES}/${gameId}`;
      }));
    }
  }

  /**
   * @description
   *    The method is doing reset of the game data in the internal storage.
   */
  const closeGame = () => {
    dispatch(resetAndCloseGameData());
  }

  // ========================= VIEW METHODS/ACTIONS ========================= //

  /**
   * @description - If any fetching action is still in process then show the
   * loader component
   * ToDo correct the loading process
   */
  if (isLoading && gameId && status !== EnumGameStatus.playing && isEditMode) {
    return (
      <Loading />
    )
  }

  /**
   * @description - The provider view
   */
  return (
    <ContextGameEdit.Provider
      value={{
        // Data
        error,
        isLoading,
        game,
        gameMechanic,
        page,
        totalPages: game?.content?.length ?? 0,
        // Methods
        updateGame,
        submitUpdatedGame,
        createGameMechanic,
        changePage,
        closeGame,
        isShowHints,
        showTheHintsPopup,
        isShowInstructions,
        showTheInstructionsPopup
      }}
    >
      {children}
    </ContextGameEdit.Provider>
  );
}
