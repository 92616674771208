import { FilterData } from './index';

const filterByTitle = (row:FilterData, filterData:FilterData) => {
  if ('title' in filterData && filterData.title) {
    // @ts-ignore
    return row.title && row.title.toLowerCase().includes(filterData.title?.toLowerCase());
  } else {
    return true;
  }
}

const filterByCreatedUser = (row:FilterData, filterData:FilterData) => {
  if ('created_by' in filterData && filterData.created_by) {
    // @ts-ignore
    return row.created_by && row.created_by.toLowerCase().includes(filterData.created_by?.toLowerCase());
  } else {
    return true;
  }
}

const filterByStatus = (row:FilterData, filterData:FilterData) => {
  if ('status' in filterData && filterData.status) {
    return  row.status && row.status === filterData.status;
  }else {
    return  true;
  }
}

const filterByDate=(row:FilterData, filterData:FilterData)=>{
  if('date' in filterData && filterData.date){
    return row.created_at === filterData.created_at //TODO add a formatting lib
  }else {
    return  true;
  }
}
const filterByUpDate=(row:FilterData, filterData:FilterData)=>{
  if('updated' in filterData && filterData.updated){
    return Date.parse(row.updated_at as string) === Date.parse(`${filterData.updated} 00:00`) //TODO add a formatting lib
  }else {
    return  true;
  }
}

export const filterFunctionsArr = [filterByTitle, filterByCreatedUser, filterByStatus, filterByDate, filterByUpDate]